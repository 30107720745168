import { useState } from 'react';
import lightTheme from '../images/dark.svg';
import darkTheme from '../images/light.svg';


const Theme = () => {
    const [themeIcon, setThemeIcon] = useState(darkTheme);


    function changeThemeColor(newColor) {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        if (metaThemeColor) {
            metaThemeColor.setAttribute('content', newColor);
        }
    }

    const setTheme = (theme) => {
        if (theme === "dark") {
            setThemeIcon(darkTheme)
            changeThemeColor("#020817")
            document.documentElement.classList.add('dark-theme');
        } else {
            setThemeIcon(lightTheme)
            changeThemeColor("#fefefe")
            document.documentElement.classList.remove('dark-theme');
        }
    }
    const toggleTheme = () => {
        let theme = localStorage.getItem("theme");
        if (!theme || theme === "light") {
            setTheme("dark");
            localStorage.setItem("theme", "dark");
        } else {
            setTheme("light");
            localStorage.setItem("theme", "light");
        }
    }



    return { toggleTheme, setTheme, themeIcon };
};

export default Theme;
