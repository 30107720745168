import { useState, useEffect } from 'react';
import '../css/App.css';
import '../css/Market.css';
import { starOutline, star, starHalfOutline, heartOutline, shareOutline, heart } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import RatingModal from '../components/RatingModal';
import useAuth from './useAuth';
import axios from 'axios';

import { successToast, errorToast } from './CustomToast';
import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';



function Market({ data }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [stars, setStars] = useState([]);

    useEffect(() => {
        const fullStars = Math.floor(data.averageRating);
        const hasHalfStar = data.averageRating - fullStars >= 0.5;
        const newStars = [];
    
        for (let i = 0; i < fullStars; i++) {
            newStars.push(<IonIcon key={i} icon={star} />);
        }
    
        if (hasHalfStar) {
            newStars.push(<IonIcon key="half" icon={starHalfOutline} />);
        }
    
        const emptyStars = 5 - newStars.length;
        for (let i = 0; i < emptyStars; i++) {
            newStars.push(<IonIcon key={`empty-${i}`} icon={starOutline} />);
        }
    
        setStars(newStars);
    }, [data.averageRating]);
    const { loggedIn } = useAuth(); 


    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const images = data.images;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    const handleImageChange = () => {
        setIsFading(true);
        setTimeout(() => {
            const nextIndex = (currentImageIndex + 1) % images.length;
            setCurrentImageIndex(nextIndex);
            setIsFading(false);
        }, 500); 
    };

    const [heartOption, setHeartOption] = useState(heartOutline)

    const handleHeart = async () => {
        if(heartOption == heart){
           setHeartOption(heartOutline);
        } else {
            successToast(`Favourited ${data.name}!`, 5000);
            setHeartOption(heart)
        }
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/like`,
                    { market_id: data.id },
                    { withCredentials: true }
                );
            } catch (error) {
                errorToast("Error liking", 5000)
                throw new Error('Failed to submit rating');
            }
        
    }
    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://tfm.sebtheo.co.uk/market?id=${data.id}`)
          .then(() => {
            successToast("Copied to clipboard!", 5000)
          })
          .catch((error) => {
            console.error('Error copying to clipboard: ', error);
          });
      }

    useEffect(() => {
        if(data.liked){
            setHeartOption(heart)
        }

    },[])
    return (
        <div className='Market'>
            <div className='market-section'>
                <div className='mkt-image'>
                    <img
                        src={images[currentImageIndex]}
                        alt={data.name}
                        onClick={handleImageChange}
                        style={{ transition: 'opacity 0.5s ease', opacity: isFading ? 0 : 1 }}
                    />
                </div>
            </div>
            <div className='market-section'>
                <div>
                    <div className='mkt-header'>
                        <Link to={`/market?id=${data.id}`} className='mkt-title'>
                            {data.name}
                        </Link>
                        <div className='mkt-actions'>
                            <button className='mkt-act-btn' onClick={toggleModal}>
                                <IonIcon icon={starOutline} />
                            </button>

                            <button className='mkt-act-btn' onClick={loggedIn ? handleHeart : toggleModal}>
                                <IonIcon icon={heartOption} />
                            </button>
                            <button className='mkt-act-btn' onClick={() => copyToClipboard()}>
                                <IonIcon icon={shareOutline} />
                            </button>
                            {isModalOpen && <RatingModal closeModal={toggleModal} name={data.name} loggedIn={loggedIn} id={data.id} />}


                        </div>
                    </div>
                    <div className='mkt-tags-feedback'>
                        <div className='mkt-tags'>
                        {data.tags.map((tag, index) => (
                            <a key={index} href='/'>{tag}</a>
                        ))}
                        </div>
                        <div className='avgrating'>
                            <div>{stars}</div> <p>({data.averageRating.toFixed(1)}/5)</p>
                        </div>
                    </div>
                    <div className='mkt-description'>
                        {data.description.split("\n").map((part, index) => (
                            <span key={index}>{part}</span>
                        ))}
                    </div>
                </div>
            </div>
            <Toaster position='bottom-left' reverseOrder={true} />

        </div>
    )
}

export default Market;
