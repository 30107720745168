import Header from '../components/Header';
import '../css/App.css';
import '../css/Home.css';
import Markets from './Markets'
import { Link } from 'react-router-dom';
import { searchOutline, personOutline, arrowBackOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';



function Home() {
    return (
        <div>
            <Header page={"Home"} />
            <div className='App'>
                <div className='home'>

                    <h1 className='home-title'>Top Food Markets in London</h1>
                    <h2 className='home-subtitle'>Explore London's vibrant culinary and dining scene. </h2>
                    <div className='home-action-btns'>
                        <Link to='/markets' className="home-action">
                            <IonIcon icon={searchOutline} className='home-icon' /><span>Explore</span>
                        </Link>
                        <div className='sign-up-info'>
                            <Link to='/account' className="home-action">
                                <IonIcon icon={personOutline} className='home-icon' /><span>Sign up</span>
                            </Link>
                            {/* <Link to='/account' className=" sign-up-btn">
                                <IonIcon icon={arrowBackOutline} /><span>Enjoy member exclusive benefits for free!</span>
                            </Link> */}
                        </div>

                    </div>



                </div>

            </div>
        </div>
    )
}

export default Home;