import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/App.css';
import '../css/Market.css';
import Market from '../components/Market';
import Header from '../components/Header';

function Markets() {
    const [marketData, setMarketData] = useState([]);

    useEffect(() => {
        // Make the Axios request to fetch market data from your API
        axios.get(`${process.env.REACT_APP_API_URL}/topmarkets`, {withCredentials:true})
            .then(response => {
                // Once the data is fetched, update the state with the retrieved data
                setMarketData(response.data);
            })
            .catch(error => {
                console.error('Error fetching market data:', error);
            });
    }, []); // Empty dependency array to ensure the effect runs only once

    return (
        <div>
            <Header page={"Markets"} />
            <div className='App'>
                    <div className='market-title'>
                        <h1>Best Markets</h1>
                    </div>

                    <div className='Markets'>
                        {marketData.map((market, index) => (
                            <Market key={index} data={market} />
                        ))}
                    </div>            

            </div>
        </div>

    );
}

export default Markets;
