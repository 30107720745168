import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../css/Header.css';
import Theme from './Theme';
import Logo from '../images/logo.png';




function Header({ page }) {
    const { toggleTheme, setTheme, themeIcon } = Theme();
    useEffect(() => {
        let theme = localStorage.getItem("theme");
        setTheme(theme);
    }, [setTheme]);
    return (
        <div className='header-wrapper'>
            <div className='header'>
                <Link to="/" className='header-left'>
                    <img src={Logo} />
                </Link>
                <div className='header-right'>
                    <Link to="/" className={`nav-link ${page === "Home" ? "nav-active" : ""}`}>
                        Home
                    </Link>
                    <Link to="/markets" className={`nav-link ${page === "Markets" ? "nav-active" : ""}`}>
                        Markets
                    </Link>
                    <Link to="/account" className={`nav-link ${page === "Account" ? "nav-active" : ""}`}>
                        Account
                    </Link>
                    <span onClick={toggleTheme} className='theme-switch'>
                        <img src={themeIcon} alt="themeIcon" />
                    </span>
                </div>

            </div>
        </div>
    )
}


export default Header;