
function RandomizedBackground() {
    const randomHueLinear = Math.floor(Math.random() * 360);
    const randomSaturationLinear = Math.floor(Math.random() * 100);
    const randomLightnessLinear = Math.floor(Math.random() * 100);

    const linearGradient = `linear-gradient(to right, hsl(var(--background)), transparent)`;

    const radialGradients = Array.from({ length: 3 }, (_, index) => {
        let positionX, positionY;
        if (index === 0) {
            positionX = 75;
            positionY = 25;
        } else if (index === 1) {
            positionX = 90;
            positionY = 75;
        } else {
            positionX = 75;
            positionY = 75;
        }

        const randomRedRadial = Math.floor(Math.random() * 256);
        const randomGreenRadial = Math.floor(Math.random() * 256);
        const randomBlueRadial = Math.floor(Math.random() * 256);
        const randomAlphaRadial = Math.random(); // Random alpha between 0 and 1

        return `radial-gradient(circle at ${positionX}% ${positionY}%, 
                        rgba(${randomRedRadial}, ${randomGreenRadial}, ${randomBlueRadial}, 0.6),
                        transparent 400px)`;
    });

    const backgroundGradient = `${linearGradient}, ${radialGradients.join(', ')}`;

    return backgroundGradient;
}

export default RandomizedBackground;
