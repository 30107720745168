import Header from '../components/Header';
import '../css/App.css';
import '../css/Account.css';
import React, { useEffect } from 'react';
import Toaster from 'react-hot-toast';
import Profile from '../components/Profile';
import Access from '../components/Access';
import useAuth from '../components/useAuth';

function Gateway() {

    const { loggedIn, userEmail, userName, login, logout, checkUser, userType } = useAuth(); // Use the custom hook

    useEffect(() => {
        checkUser()
    }, [])
    return (

        <div>
            <Header page={"Account"} />
            <div className='App'>
                {loggedIn ? (
                    <Profile email={userEmail} username={userName}/>
                ) : (
                    <Access onLogin={login} />
                )}
            </div>
        </div>

    )
}

export default Gateway;