import '../css/Profile.css'
import useAuth from './useAuth';
import { colorWandOutline, logOutOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import RandomizedBackground from './RandomizedBackground';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';



function Profile({ email, username }) {
    const { logout } = useAuth();

    function handleLogout() {
        logout();
    }
    const [backgroundGradient, setBackgroundGradient] = useState('');

    useEffect(() => {
        setBackgroundGradient(RandomizedBackground());
    }, []);

    function handleBackgroundChange() {
        setBackgroundGradient(RandomizedBackground());
    }

    const [userStats, setUserStats] = useState(null);
    const [ratings, setRatings] = useState([]);
    const [likes, setLikes] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/userStats`, { withCredentials: true });
                setUserStats(response.data.stats);
                setRatings(response.data.ratings);
                setLikes(response.data.likedMarkets);
            } catch (error) {
                console.error('Error fetching user stats:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='Profile'>
            <div className='profile-wrapper'>
                <div className='profile-header' style={{ background: backgroundGradient }}>
                    <div className='logout-btn' onClick={handleLogout}>
                        <IonIcon icon={logOutOutline} />
                    </div>
                    <div className='logout-btn wand-btn' onClick={handleBackgroundChange}>
                        <IonIcon icon={colorWandOutline} />
                    </div>
                    <img className='pfp-img' src={"https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg"} />
                    <div className='profile-header-details'>
                        <p>{username}</p>
                        <p>{email}</p>
                    </div>
                </div>
                <div className='profile-stats'>
                    <div className='profile-stat-bx'>
                        <p className='stat-title'>Markets rated</p>
                        <p className='stat-result'>{userStats && userStats.count ? userStats.count : '...'}</p>
                    </div>
                    <div className='profile-stat-bx'>
                        <p className='stat-title'>Favourite type</p>
                        <p className='stat-result'>{userStats && userStats.tag ? userStats.tag : '...'}</p>
                    </div>
                    <div className='profile-stat-bx'>
                        <p className='stat-title'>Best market</p>
                        <p className='stat-result'>{userStats && userStats.favourite ? userStats.favourite : '...'}</p>
                    </div>
                </div>
                <div className='profile-rated'>


                    <div className='table-container'>
                        <h1>My Likes</h1>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Market</th>
                                    <th>Link</th>

                                </tr>
                            </thead>
                            <tbody>
                                {likes.map((like, index) => (
                                    <tr key={index}>
                                        <td>{like.market_name}</td>
                                        <td><Link to={`/market?id=${like.market_id}`}> Link </Link></td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='profile-rated'>


                <div className='table-container'>
                    <h1>My Ratings</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Market</th>
                                <th>Rating</th>
                                <th>Comment</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ratings.map((rating, index) => (
                                <tr key={index}>
                                    <td>{rating.market_name}</td>
                                    <td>{rating.rating}/5</td>
                                    <td>{rating.content.length > 36 ? `${rating.content.substring(0, 36)}...` : rating.content}</td>
                                    <td>{rating.timestamp}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
        </div >
    )
}

export default Profile;