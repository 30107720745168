// RatingModal.jsx
import React, { useState, useRef } from 'react';
import { starOutline, star, close, arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import '../css/App.css';
import '../css/Modal.css';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import { successToast, errorToast } from './CustomToast';
import { Toaster } from 'react-hot-toast';


function RatingModal({ closeModal, name, id, loggedIn }) {
    const [rating, setRating] = useState(0);
    const [content, setContent] = useState('');

    const handleStarClick = (starNumber) => {
        setRating(starNumber);
    };

    const handleSubmit = async () => {
        try {
            if (content.length > 24) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/submit`,
                    { rating, content, mkt_id: id },
                    { withCredentials: true }
                );

                console.log('Rating submitted successfully:', response.data);
                successToast("Rating submitted!", 5000)
                setContent('');
                closeModal();
            } else {
                errorToast("Reason for rating not long enough", 5000);
            }
        } catch (error) {
            // If an error occurs, log the error
            errorToast("Error submitting rating", 5000)
            console.error('Error submitting rating:', error);
            throw new Error('Failed to submit rating');
        }
    };

    return (
        <div className="rm-wrapper">
            <div className='rm-container'>
                {loggedIn ? (
                    <>
                        <div className='rm-section'>
                            <div className='rm-head'>
                                <span className='rm-title'> {name}</span>
                                <button className='rm-close' onClick={closeModal}>
                                    <IonIcon icon={close} />
                                </button>
                            </div>
                            <div className='rating-head'>
                                <span className='rm-subtitle'> What would you rate this market?</span>
                                <div className='stars-div'>
                                    {[1, 2, 3, 4, 5].map((starNumber) => (
                                        <span
                                            key={starNumber}
                                            className="star"
                                            onClick={() => handleStarClick(starNumber)}
                                        >
                                            <IonIcon icon={starNumber <= rating ? star : starOutline} />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div >
                        <div className='rm-hr' />
                        <div className='rm-section'>
                            <div className='response-head'>
                                <span className='rm-subtitle'>
                                    Why did you rate <strong>{name}</strong> this way?
                                </span>
                            </div>
                            <div className='response-content'>
                                <textarea
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    placeholder="Enter your feedback here..."
                                ></textarea>                            </div>
                            <button className='response-submit' onClick={handleSubmit}> Submit </button>

                        </div>
                        <div className='rm-hr' />
                        <div className='rm-section'>
                            <div className='reccomend-head'>
                                <span className='rm-subtitle'>
                                    View similar markets:
                                </span>
                            </div>
                            <section className="slider-wrapper">
                                <div className="slider-container">
                                    <div className='reccomend-slideshow'>
                                        <Link to={`/market?id=1`} className='slides-item'>
                                            <img src={"https://media.timeout.com/images/106041094/750/562/image.webp"} alt="Borough Market" />
                                            <label>Camden Market</label>
                                        </Link>
                                        <Link to={`/market?id=2`} className='slides-item'>
                                            <img src={"https://media.timeout.com/images/105978735/750/562/image.webp"} alt="Arcade Food Hall" />
                                            <label>Arcade Food Hall</label>
                                        </Link>
                                        <Link to={`/market?id=3`} className='slides-item'>
                                            <img src={"https://media.timeout.com/images/105215045/750/562/image.webp"} alt="Broadway Market" />
                                            <label>Old Spitalfields Market</label>
                                        </Link>
                                        <a href="/markets" className='slides-more'>
                                            View more
                                            <IonIcon icon={arrowForwardOutline} className='more-btn' />

                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </>
                ) : (
                    <div className='rm-section'>
                        <div className='response-head'>
                            <span className='rm-subtitle'>
                                Do you want to rate <strong>{name}</strong>?
                            </span>
                            <button className='rm-close' onClick={closeModal}>
                                <IonIcon icon={close} />
                            </button>
                        </div>
                        <div>
                            Sign up to rate your favourite markets and gain access to a tonne of cool features!
                        </div>
                        <div className='sign-up-bonus-div'>
                            <Link to='/account' className='sign-up-bonus'>
                                Access features ✨
                            </Link>
                        </div>
                    </div>
                )}

            </div >
        </div >
    );
}

export default RatingModal;
