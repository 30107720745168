import React, { useState } from 'react';
import axios from 'axios';
import '../css/App.css';
import '../css/Account.css';
import { logInOutline, colorWandOutline, logoGoogle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { successToast, errorToast } from './CustomToast';
import { Toaster } from 'react-hot-toast';
import useAuth from './useAuth';


function Access( { onLogin }) {
    const [signInFlow, setSignInFlow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { loggedIn, userEmail, userName, login, logout, checkUser, userType } = useAuth(); // Use the custom hook

    const toggleFlow = () => {
        setSignInFlow(prevState => !prevState);
    }

    const handleFlow = () => {
        if (email.length <= 8) {
            errorToast("Email is not long enough", 5000)
            return false
        }
        if (!email.includes("@")) {
            errorToast("That is not an email", 5000)
            return false
        }
        if (password.length <= 4) {
            errorToast("Password is not long enough", 5000)
            return false
        }

        const hasNumber = /\d/.test(password);

        if (!hasNumber) {
            errorToast("Password does not include a number")
            return false
        }

        const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);

        if (!hasSpecialCharacter) {
            errorToast("Password does not include a special character")
            return false
        }

        return true

    }

    const handleCreateAccount = async () => {
        try {
            if (password !== confirmPassword) {
                errorToast('Passwords do not match', 5000);
                return;
            }

            if(!handleFlow()){
                return false
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/createAccount`, { cemail: email, cpassword: password },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            if (response.data.success) {
                successToast('Account created successfully', 5000);
                onLogin(email)
                window.location.reload();
                
            } else {
                errorToast(response.data.message, 5000);
            }
        } catch (errorRespons) {
            errorToast('Internal Server Error', 5000);
        }
    }

    const handleSignIn = async () => {
        try {

            if(!handleFlow()){
                return
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }
            );
            if (response.status === 200) {
                successToast('Login successful', 5000);
                onLogin(email);
                window.location.reload();

            } else {
                errorToast(response.data.error, 5000);
            }
        } catch (errorRespons) {
            errorToast('Internal Server Error', 5000);
        }
    }

    return (
        <div className='account-wrapper'>
            <div className={!signInFlow ? 'account-section account-section-active' : 'account-section'}>
                {signInFlow ? (
                    <div className='account'>
                        <div className='account-row'>
                            <h1>Already have an account?</h1>
                            <span>Jump right back in and access your premium features.</span>
                        </div>
                        <div className='account-row'>
                            <button onClick={toggleFlow} className='button-with-icon'>
                                <IonIcon icon={logInOutline} />
                                <p className='alt-flow'>Sign In</p>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className='account'>
                        <div className='account-row'>
                            <h1>Sign In</h1>
                            <span>Enter your email and password to sign in</span>
                        </div>

                        <div className='account-row'>
                            <input placeholder='name@email.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <input type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />

                            <button onClick={handleSignIn}> Sign In with Email </button>
                        </div>
                        <div className='account-row account-divider'>
                            <div className='account-hr' />
                            <p> OR </p>
                            <div className='account-hr' />
                        </div>
                        <div className='account-row'>
                            <button className='button-with-icon coming-soon-hover'> <IonIcon icon={logoGoogle} /> Google </button>
                        </div>
                    </div>
                )}
            </div>

            <div className={signInFlow ? 'account-section account-section-active' : 'account-section'}>
                {!signInFlow ? (
                    <div className='account'>
                        <div className='account-row'>
                            <h1>
                                Ready to join?
                            </h1>
                            <span>
                                Unlock exclusive benefits and access premium features!
                            </span>
                        </div>
                        <div className='account-row'>
                            <button onClick={toggleFlow} className='button-with-icon'> <IonIcon icon={colorWandOutline} /> Create Account </button>
                        </div>
                    </div>
                ) : (
                    <div className='account'>
                        <div className='account-row'>
                            <h1>Create an Account</h1>
                            <span>Enter your email below to create your account</span>
                        </div>

                        <div className='account-row'>
                            <input placeholder='name@email.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            <input type='password' placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            <button onClick={handleCreateAccount}> Create </button>
                        </div>
                        <div className='account-row account-divider'>
                            <div className='account-hr' />
                            <p> OR </p>
                            <div className='account-hr' />
                        </div>
                        <div className='account-row'>
                            <button className='button-with-icon coming-soon-hover'> <IonIcon icon={logoGoogle} /> Google </button>
                        </div>
                    </div>
                )}
            </div>
            <Toaster position='bottom-left' reverseOrder={true} />

        </div>
    )
}

export default Access;
