import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import '../css/App.css';
import '../css/Market.css';
import Market from '../components/Market';
import Header from '../components/Header';

function MarketPage() {
    const [marketData, setMarketData] = useState([]);
    const location = useLocation(); // Get the location object using useLocation

    useEffect(() => {
        // Extract the id parameter from the query string
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        // Make the Axios request to fetch market data from your API
        axios.get(`${process.env.REACT_APP_API_URL}/topmarkets?id=${id}`)
            .then(response => {
                // Once the data is fetched, update the state with the retrieved data
                setMarketData(response.data);
            })
            .catch(error => {
                console.error('Error fetching market data:', error);
            });
    }, [location.search]); // Dependency array includes location.search

    return (
        <div>
            <Header page={"Markets"} />
            <div className='App'>
                <div className='Markets'>
                    {marketData.map((market, index) => (
                        <Market key={index} data={market} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MarketPage;
