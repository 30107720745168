import { toast } from 'react-hot-toast';

export const successToast = (message, duration) => {
  toast.success(message, {
    position: "bottom-right",
    style: {
      border: '1px solid hsl(var(--primary) / .2)',
      padding: '16px',
      background: 'hsl(var(--background))',
      color: 'hsl(var(--foreground))',
    },
    duration: duration
  });
}

export const errorToast = (message, duration) => {
  toast.error(message, {
    position: "bottom-right",
    style: {
      border: '1px solid hsl(var(--primary) / .2)',
      padding: '16px',
      background: 'hsl(var(--background))',
      color: 'hsl(var(--foreground))',
    },
    duration: duration
  });
}


